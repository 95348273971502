<template>
  <header class="header__banner">
    <div class="d-flex logo__wrap">
      <router-link :to="{ name: 'home' }"
        ><img src="@/assets/img/logo.png" alt="" class="img-fluid"
      /></router-link>
    </div>
    <ul class="navbar__banner d-none d-xl-flex">
      <!-- <li class="nav__item">
        <router-link
          :to="{ name: 'home' }"
          class="nav__link"
          :class="{ active: currentRoute === 'home' }"
        >
          {{ $t("start_trading") }}
        </router-link>
      </li> -->
      <li class="nav__item">
        <router-link
          :to="{ name: 'tools' }"
          class="nav__link"
          :class="{ active: currentRoute === 'tools' }"
        >
          {{ $t("tools_platform") }}
        </router-link>
      </li>
      <li class="nav__item">
        <router-link
          :to="{ name: 'affiliates' }"
          class="nav__link"
          :class="{ active: currentRoute === 'affiliates' }"
        >
          {{ $t("affiliates_partners") }}
        </router-link>
      </li>
      <li class="nav__item">
        <router-link
          :to="{ name: 'about-us' }"
          class="nav__link"
          :class="{ active: currentRoute === 'about-us' }"
        >
          {{ $t("about_us") }}
        </router-link>
      </li>
      <li class="nav__item">
        <router-link
          :to="{ name: 'company-support' }"
          class="nav__link"
          :class="{ active: currentRoute === 'company-support' }"
        >
          {{ $t("company_support1") }}
        </router-link>
      </li>
      <li class="nav__item">
        <a :href="'https://mycabinet.ipo-markets.net/signup?lang=' + $i18n.locale" class="nav__link">{{ $t("open_account") }}</a>
      </li>
      <!-- <li class="nav__item">
        <a href="#" class="nav__link">{{ $t("open_demo_account") }}</a>
      </li> -->
      <li class="nav__item">
        <a :href="'https://mycabinet.ipo-markets.net/login?lang=' + $i18n.locale" class="nav__link">{{ $t("login") }}</a>
      </li>
      <div class="dropdown-lang">
        <button
          class="dropdown-toggle text-uppercase"
          type="button"
          id="dropdownLang"
          data-bs-toggle="dropdown"
          aria-expanded="false"
        >
          {{ $i18n.locale }}
        </button>
        <LocaleDropdown />
      </div>
    </ul>
    <ul class="navbar__banner d-flex d-xl-none align-items-center">
      <li class="nav__item">
        <a :href="'https://mycabinet.ipo-markets.net/signup?lang=' + $i18n.locale" class="nav__link">{{ $t("open_account") }}</a>
      </li>
      <li class="nav__item pe-custom-100">
        <a :href="'https://mycabinet.ipo-markets.net/login?lang=' + $i18n.locale" class="nav__link d-flex align-items-center">
          <svg
            class="me-2"
            width="13"
            height="16"
            viewBox="0 0 13 16"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M10.2375 0H5.34093C4.6916 0 4.06887 0.257945 3.60972 0.71709C3.15058 1.17623 2.89263 1.79897 2.89263 2.4483V5.7127H3.70873V2.4483C3.70873 2.01541 3.8807 1.60026 4.18679 1.29416C4.49289 0.988063 4.90805 0.816099 5.34093 0.816099H10.2375C10.6704 0.816099 11.0856 0.988063 11.3917 1.29416C11.6978 1.60026 11.8697 2.01541 11.8697 2.4483V13.0576C11.8697 13.4905 11.6978 13.9056 11.3917 14.2117C11.0856 14.5178 10.6704 14.6898 10.2375 14.6898H5.34093C4.90805 14.6898 4.49289 14.5178 4.18679 14.2117C3.8807 13.9056 3.70873 13.4905 3.70873 13.0576V9.79319H2.89263V13.0576C2.89263 13.7069 3.15058 14.3297 3.60972 14.7888C4.06887 15.2479 4.6916 15.5059 5.34093 15.5059H10.2375C10.8869 15.5059 11.5096 15.2479 11.9687 14.7888C12.4279 14.3297 12.6858 13.7069 12.6858 13.0576V2.4483C12.6858 1.79897 12.4279 1.17623 11.9687 0.71709C11.5096 0.257945 10.8869 0 10.2375 0ZM0.444336 7.34489H8.80935L6.15703 4.69257L6.69892 4.0805L10.3714 7.75294L6.69892 11.4254L6.15703 10.8133L8.80935 8.16099H0.444336V7.34489Z"
              fill="white"
            />
          </svg>
          {{ $t("login") }}
        </a>
      </li>
      <span class="menu-trigger">
        <i class="menu-trigger-bar top"></i>
        <i class="menu-trigger-bar middle"></i>
        <i class="menu-trigger-bar bottom"></i>
      </span>
      <span class="close-trigger">
        <i class="close-trigger-bar left"></i>
        <i class="close-trigger-bar right"></i>
      </span>
    </ul>
    <div class="container-mobile d-block d-xl-none pi-none">
      <div class="inner-container">
        <i class="menu-bg top"></i>
        <i class="menu-bg middle"></i>
        <i class="menu-bg bottom"></i>
        <div class="menu-container">
          <ul class="menu">
            <li>
              <router-link
                :to="{ name: 'home' }"
                class="nav__link"
                :class="{ active: currentRoute === 'home' }"
              >
                {{ $t("start_trading") }}
              </router-link>
            </li>
            <li>
              <router-link
                :to="{ name: 'tools' }"
                class="nav__link"
                :class="{ active: currentRoute === 'tools' }"
              >
                {{ $t("tools_platform") }}
              </router-link>
            </li>
            <li>
              <router-link
                :to="{ name: 'affiliates' }"
                class="nav__link"
                :class="{ active: currentRoute === 'affiliates' }"
              >
                {{ $t("affiliates_partners") }}
              </router-link>
            </li>
            <li>
              <router-link
                :to="{ name: 'about-us' }"
                class="nav__link"
                :class="{ active: currentRoute === 'about-us' }"
              >
                {{ $t("about_us") }}
              </router-link>
            </li>
            <li>
              <router-link
                :to="{ name: 'company-support' }"
                class="nav__link"
                :class="{ active: currentRoute === 'company-support' }"
              >
                {{ $t("company_support1") }}
              </router-link>
            </li>
            <li>
              <div class="dropdown-lang">
        <button
          class="dropdown-toggle text-uppercase"
          type="button"
          id="dropdownLang"
          data-bs-toggle="dropdown"
          aria-expanded="false"
        >
          {{ $i18n.locale }}
        </button>
        <LocaleDropdown />
      </div>
            </li>
          </ul>
        </div>
      </div>
    </div>
  </header>
</template>

<script>
/* eslint-disable */
import { Power2, Power4, TimelineMax } from "gsap";
import LocaleDropdown from "@/components/LocaleDropdown";
// import $ from 'jquery'

global.jQuery = require("jquery");
var $ = global.jQuery;
window.$ = $;
export default {
  name: "Menu",
  components: {
    LocaleDropdown,
  },
  computed: {
    currentRoute() {
      return this.$route.name;
    },
  },
  mounted() {
    //OPEN TRIGGER
    var openTrigger = $(".menu-trigger");
    var openTriggerTop = openTrigger.find(".menu-trigger-bar.top");
    var openTriggerMiddle = openTrigger.find(".menu-trigger-bar.middle");
    var openTriggerBottom = openTrigger.find(".menu-trigger-bar.bottom");

    //CLOSE TRIGGER
    var closeTrigger = $(".close-trigger");
    var closeTriggerLeft = closeTrigger.find(".close-trigger-bar.left");
    var closeTriggerRight = closeTrigger.find(".close-trigger-bar.right");

    var mobileMenu = $(".container-mobile");

    //LOGO
    var logo = $(".logo");

    //MENU
    var menu = $(".menu");
    var menuTop = $(".menu-bg.top");
    var menuMiddle = $(".menu-bg.middle");
    var menuBottom = $(".menu-bg.bottom");

    //TL
    var tlOpen = new TimelineMax({ paused: true });
    var tlClose = new TimelineMax({ paused: true });

    //OPEN TIMELINE
    tlOpen
      .add("preOpen")
      .to(
        logo,
        0.4,
        {
          scale: 0.8,
          opacity: 0,
          ease: Power2.easeOut,
        },
        "preOpen"
      )
      .to(
        openTriggerTop,
        0.4,
        {
          x: "+80px",
          y: "-80px",
          delay: 0.1,
          ease: Power4.easeIn,
          onComplete: function () {
            closeTrigger.css("z-index", "25");
          },
        },
        "preOpen"
      )
      .to(
        openTriggerMiddle,
        0.4,
        {
          x: "+=80px",
          y: "-=80px",
          ease: Power4.easeIn,
          onComplete: function () {
            openTrigger.css("visibility", "hidden");
            mobileMenu.removeClass('pi-none');
          },
        },
        "preOpen"
      )
      .to(
        openTriggerBottom,
        0.4,
        {
          x: "+=80px",
          y: "-=80px",
          delay: 0.2,
          ease: Power4.easeIn,
        },
        "preOpen"
      )
      .add("open", "-=0.4")
      .to(
        menuTop,
        0.8,
        {
          y: "13%",
          ease: Power4.easeInOut,
        },
        "open"
      )
      .to(
        menuMiddle,
        0.8,
        {
          scaleY: 1,
          ease: Power4.easeInOut,
        },
        "open"
      )
      .to(
        menuBottom,
        0.8,
        {
          y: "-114%",
          ease: Power4.easeInOut,
        },
        "open"
      )
      .fromTo(
        menu,
        0.6,
        {
          y: 30,
          opacity: 0,
          visibility: "hidden",
        },
        {
          y: 0,
          opacity: 1,
          visibility: "visible",
          ease: Power4.easeOut,
        },
        "-=0.2"
      )
      .add("preClose", "-=0.8")
      .to(
        closeTriggerLeft,
        0.8,
        {
          x: "-=100px",
          y: "+=100px",
          ease: Power4.easeOut,
        },
        "preClose"
      )
      .to(
        closeTriggerRight,
        0.8,
        {
          x: "+=100px",
          y: "+=100px",
          delay: 0.2,
          ease: Power4.easeOut,
        },
        "preClose"
      );

    //CLOSE TIMELINE
    tlClose
      .add("close")
      .to(
        menuTop,
        0.2,
        {
          backgroundColor: "#6295ca",
          ease: Power4.easeInOut,
          onComplete: function () {
            logo.css("z-index", "25");
            closeTrigger.css("z-index", "5");
            openTrigger.css("visibility", "visible");
            mobileMenu.addClass('pi-none');
          },
        },
        "close"
      )
      .to(
        menuMiddle,
        0.2,
        {
          backgroundColor: "#6295ca",
          ease: Power4.easeInOut,
        },
        "close"
      )
      .to(
        menuBottom,
        0.2,
        {
          backgroundColor: "#6295ca",
          ease: Power4.easeInOut,
        },
        "close"
      )
      .to(
        menu,
        0.6,
        {
          y: 20,
          opacity: 0,
          ease: Power4.easeOut,
          onComplete: function () {
            menu.css("visibility", "hidden");
          },
        },
        "close"
      )
      .to(
        logo,
        0.8,
        {
          scale: 1,
          opacity: 1,
          ease: Power4.easeInOut,
        },
        "close",
        "+=0.2"
      )
      .to(
        menuTop,
        0.8,
        {
          y: "-113%",
          ease: Power4.easeInOut,
        },
        "close",
        "+=0.2"
      )
      .to(
        menuMiddle,
        0.8,
        {
          scaleY: 0,
          ease: Power4.easeInOut,
        },
        "close",
        "+=0.2"
      )
      .to(
        menuBottom,
        0.8,
        {
          y: "23%",
          ease: Power4.easeInOut,
          onComplete: function () {
            menuTop.css("background-color", "#ffffff");
            menuMiddle.css("background-color", "#ffffff");
            menuBottom.css("background-color", "#ffffff");
          },
        },
        "close",
        "+=0.2"
      )
      .to(
        closeTriggerLeft,
        0.2,
        {
          x: "+=100px",
          y: "-=100px",
          ease: Power4.easeIn,
        },
        "close"
      )
      .to(
        closeTriggerRight,
        0.2,
        {
          x: "-=100px",
          y: "-=100px",
          delay: 0.1,
          ease: Power4.easeIn,
        },
        "close"
      )
      .to(
        openTriggerTop,
        1,
        {
          x: "-=80px",
          y: "+=80px",
          delay: 0.2,
          ease: Power4.easeOut,
        },
        "close"
      )
      .to(
        openTriggerMiddle,
        1,
        {
          x: "-=80px",
          y: "+=80px",
          ease: Power4.easeOut,
        },
        "close"
      )
      .to(
        openTriggerBottom,
        1,
        {
          x: "-=80px",
          y: "+=80px",
          delay: 0.1,
          ease: Power4.easeOut,
        },
        "close"
      );

    //EVENTS
    openTrigger.on("click", function () {
      if (tlOpen.progress() < 1) {
        tlOpen.play();
        // document.querySelector('.container-mobile').cla
        // $('.container-mobile').removeClass('pi-none')
      } else {
        tlOpen.restart();
        // $('.container-mobile').removeClass('pi-none')
      }
    });
    
    closeTrigger.on("click", function () {
      if (tlClose.progress() < 1) {
        tlClose.play();
        // $('.container-mobile').addClass('pi-none')
      } else {
        tlClose.restart();
        // $('.container-mobile').addClass('pi-none')
      }
    });
  },
};
</script>

<style lang="scss" scoped>
//VARIABLES
//Black
$black-100: rgba(0, 0, 0, 1);
$black-90: rgba(0, 0, 0, 0.9);
$black-80: rgba(0, 0, 0, 0.8);
$black-70: rgba(0, 0, 0, 0.7);
$black-60: rgba(0, 0, 0, 0.6);
$black-50: rgba(0, 0, 0, 0.5);
$black-40: rgba(0, 0, 0, 0.4);
$black-30: rgba(0, 0, 0, 0.3);
$black-20: rgba(0, 0, 0, 0.2);
$black-10: rgba(0, 0, 0, 0.1);

//White
$white-100: rgba(255, 255, 255, 1);
$white-90: rgba(255, 255, 255, 0.9);
$white-80: rgba(255, 255, 255, 0.8);
$white-70: rgba(255, 255, 255, 0.7);
$white-60: rgba(255, 255, 255, 0.6);
$white-50: rgba(255, 255, 255, 0.5);
$white-40: rgba(255, 255, 255, 0.4);
$white-30: rgba(255, 255, 255, 0.3);
$white-20: rgba(255, 255, 255, 0.2);
$white-10: rgba(255, 255, 255, 0.1);

//Invisible
$transparent: rgba(255, 255, 255, 0);

//COLORS
$light-grey: #e0dfe1;
$light-grey-A: #f0f0f0;
$grey: #9f9e9f;
$dark-grey: #222222;
$white: #ffffff;
$blue: #0190ec;

//BMW
$light-blue: #6295ca;
$dark-blue: #13274c;
$red: #d1253b;

//ANIMATIONS stuff
$hover: all 0.2s ease-in;
$slide: all 0.3s cubic-bezier(0.55, 0, 0.1, 1);
$slide-delay: all 0.4s 0.1s cubic-bezier(0.55, 0, 0.1, 1);
$slide-slow: all 0.5s cubic-bezier(0.55, 0, 0.1, 1);
$zoom: all 1s cubic-bezier(0.55, 0, 0.1, 1);
$zoom-slow: all 2.4s cubic-bezier(0.55, 0, 0.1, 1);
//MIXINS
// ADDS A BROWSER PREFIX TO THE PROPERTY
@mixin css3-prefix($property, $value) {
  -webkit-#{$property}: #{$value};
  -khtml-#{$property}: #{$value};
  -moz-#{$property}: #{$value};
  -ms-#{$property}: #{$value};
  -o-#{$property}: #{$value};
  #{$property}: #{$value};
}

// TRANSFORM
@mixin transform($params) {
  @include css3-prefix("transform", $params);
}

//FONT IMPORT
@import url(https://fonts.googleapis.com/css?family=Noto+Sans:400,700);

body {
  background-color: lighten($dark-grey, 10%);
  padding: 50px 0;
  font-family: "Noto Sans", sans-serif;
  text-align: center;
}

.pi-none {
  pointer-events: none!important;
  opacity: 0!important;
}

//
.container-mobile {
  // pointer-events: none;
  position: absolute;
  margin: 0 auto;
  max-width: 320px;
  width: 100%;
  min-height: 568px;
  border-radius: 8px;
  overflow: hidden;
  -webkit-transform: scale(1);
  z-index: 10;
  top: 60px;
  left: 50%;
  transform: translateX(-50%);
}

.menu-trigger,
.close-trigger {
  position: absolute;
  right: 20px;
  top: 8px;
  display: block;
  width: 30px;
  height: 30px;
  cursor: pointer;
  z-index: 333;

  &:hover {
    .menu-trigger-bar {
      &:before {
        width: 100%;
      }
    }

    .close-trigger-bar {
      &:before {
        width: 100%;
      }
    }
  }
}

.close-trigger {
  z-index: 5;
  top: 20px;
}

.menu-trigger-bar {
  display: block;
  width: 100%;
  height: 4px;
  background-color: $white-100;
  margin-bottom: 6px;
  transform: rotate(-45deg);
  position: relative;

  &:before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    display: block;
    width: 0%;
    height: 100%;
    background-color: $black-20;
    transition: $slide;
  }

  &.top {
    width: 50%;
  }

  &.middle {
    &:before {
      left: auto;
      right: 0;
    }
  }

  &.bottom {
    width: 50%;
    margin-left: 50%;
  }
}

.close-trigger-bar {
  display: block;
  width: 100%;
  height: 4px;
  background-color: #fff;
  position: relative;

  &:before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    display: block;
    width: 0%;
    height: 100%;
    background-color: $white-20;
    transition: $slide;
  }

  &.left {
    transform: translateX(100px) translateY(-100px) rotate(-45deg);
  }

  &.right {
    transform: translateX(-100px) translateY(-100px) rotate(45deg);
    top: -3px;
  }
}

.logo {
  display: block;
  width: 64px;
  height: 64px;
  position: absolute;
  top: 50%;
  left: 50%;
  margin-top: -52px;
  margin-left: -32px;
  text-align: center;
  z-index: 10;

  span {
    display: block;
    text-align: center;
    line-height: 62px;
    font-size: 72px;
    color: $white-100;
  }
}

.logo-title {
  position: absolute;
  top: 10px;
  left: 0;
  width: 100%;
  height: 100%;
  color: $dark-grey;
  font-size: 32px;
  line-height: 82x;
}

.logo-badge {
  position: absolute;
  display: block;
  width: 24px;
  height: 24px;
  top: 6px;
  right: -6px;
  border-radius: 100%;
  background-color: $light-blue;
  font-size: 12px;
  line-height: 2;
  text-align: center;
}

.inner-container,
.menu-container {
  position: absolute;
  height: 100%;
  width: 100%;
  left: 0;
  top: 0;
}

.inner-container {
  z-index: 20;
}

.menu-container {
  display: flex;
  align-items: center;
}

.menu {
  display: block;
  width: 100%;
  padding: 10%;
  //opacity: 0;
  //visibility: hidden;

  li {
    text-align: left;
    display: block;
    padding: 15px 0;
  }

  a {
    text-decoration: none;
    color: $dark-grey;
    display: inline-block;
    padding: 10px 0;
    position: relative;

    &:hover {
      &:before {
        opacity: 1;
        transform: translateX(0px);
      }
    }

    &:before {
      content: "";
      display: block;
      position: absolute;
      left: 0;
      bottom: 0;
      width: 18px;
      height: 4px;
      opacity: 0;
      background-color: $dark-grey;
      transform: translateX(100px);
      transition: $slide;
    }
  }
}

.menu-bg {
  position: absolute;
  display: block;
  width: 203%;
  left: -53%;
  top: -25%;
  //top: -25%;
  height: 40%;
  background-color: $white-100;

  &.middle {
    top: 27%;
    left: -53%;
    transform: rotate(-45deg) scaleY(0);
  }

  &.top {
    left: -38%;
    top: 0;
    transform: rotate(-45deg) translateY(-152%);
    //top: -25%;
  }

  &.bottom {
    //bottom: 0;
    top: 99%;
    transform: rotate(-45deg) translateY(25%);
    left: -13%;
    transform: translate(0%, 23%)
      matrix(0.70711, -0.7071, 0.7071, 0.70711, 40.3051, 0);
  }
}

#links {
  position: absolute;
  bottom: 0px;
  left: 0px;
  width: 100%;
  height: 50px;
  font-size: 13px;
  font-family: tahoma;
  color: #fff;
}

#links a {
  text-decoration: none;
  font-size: 2.3em;
  color: #fff;
}

#twitter {
  position: absolute;
  bottom: 15px;
  right: 20px;
}

#pens {
  position: absolute;
  bottom: 15px;
  left: 20px;
}

.header__banner {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-top: 60px;
}

.navbar__banner {
  display: flex;
  padding-left: 0;
  margin-bottom: 0;
  position: relative;
  padding: 10px 0;

  .nav__item,
  .dropdown-lang {
    padding-left: 45px;

    &:first-child {
      padding-left: 0;
    }
  }
}

.nav__item {
  list-style: none;
  padding-left: 45px;

  &.pe-custom-100 {
    padding-right: 60px;
  }
}

.dropdown-lang {
  list-style: none;

  .dropdown-toggle {
    background-color: transparent;
    color: #fff;
    outline: none;
    border: 0;
    @media (max-width: 1200px) {
      color: #000!important;
    }
  }
  @media (max-width: 1200px) {
    color: #000!important;
  }

  .dropdown-menu {
    &.show {
      min-width: 60px;
      border-radius: 3px;
    }
  }
}

.nav__link {
  font-size: 16px;
  color: #fff;
  text-decoration: none;

  &.active {
    color: #09ecfb;
  }
}

.logo__wrap {
  display: flex;
}
.img-fluid {
  height: 70px;
  @media (max-width: 1360px) {
    height: auto;
  }
  @media (max-width: 340px) {
    max-width: 70px;
  }
  @media (max-width: 325px) {
    max-width: 65px;
  }
}
</style>
