import { render, staticRenderFns } from "./TradeWithEagle.vue?vue&type=template&id=27b49d74&scoped=true"
import script from "./TradeWithEagle.vue?vue&type=script&lang=js"
export * from "./TradeWithEagle.vue?vue&type=script&lang=js"
import style0 from "./TradeWithEagle.vue?vue&type=style&index=0&id=27b49d74&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "27b49d74",
  null
  
)

export default component.exports