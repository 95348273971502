<template>
  <div class="container-padding trade-eagle__wrap">
    <div class="col-12 col-md-8">
      <h4 class="trade-eagle__wrap__title">{{ $t('eagle_trade_title') }}</h4>
      <div class="row">
        <div class="col-8 col-md-4">
          <div class="d-flex flex-column align-items-center">
            <span class="btn-default__third">{{ $t('tools.card_title_1') }}</span>
            <p class="trade-eagle__text">{{ $t('tools.card_text_1') }}</p>
          </div>
        </div>
        <div class="col-8 col-md-4">
          <div class="d-flex flex-column align-items-center">
            <span class="btn-default__third">{{ $t('tools.card_title_2') }}</span>
            <p class="trade-eagle__text">{{ $t('tools.card_text_2') }}</p>
          </div>
        </div>
        <div class="col-8 col-md-4">
          <div class="d-flex flex-column align-items-center">
            <span class="btn-default__third">{{ $t('tools.card_title_3') }}</span>
            <p class="trade-eagle__text">{{ $t('tools.card_text_3') }}</p>
          </div>
        </div>
      </div>
      <div class="eagle-trade__list col-12 col-md-8">
        <div class="eagle-trade__item">
          <div class="eagle-trade__name col">{{ $t('account_currency') }}</div>
          <div class="eagle-trade__title col">USD, BTC, GBP, EUR</div>
        </div>
        <div class="eagle-trade__item">
          <div class="eagle-trade__name col">{{ $t('minimum_deposit') }}</div>
          <div class="eagle-trade__title col">200 USD</div>
        </div>
        <div class="eagle-trade__item">
          <div class="eagle-trade__name col">{{ $t('leverage') }}</div>
          <div class="eagle-trade__title col">1:100</div>
        </div>
        <div class="eagle-trade__item">
          <div class="eagle-trade__name col">{{ $t('instruments') }}</div>
          <div class="eagle-trade__title col">{{ $t('floating') }}</div>
        </div>
        <div class="eagle-trade__item">
          <div class="eagle-trade__name col">{{ $t('margin_call') }}</div>
          <div class="eagle-trade__title col">{{ $t('respectively') }}</div>
        </div>
        <a :href="'https://mycabinet.ipo-markets.net/signup?lang=' + $i18n.locale" class="btn-default">{{ $t('start_trading') }}</a>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "TradeWithEagle"
}
</script>

<style lang="scss" scoped>
.trade-eagle__wrap {
  padding-top: 110px;
  //background-image: url("@/assets/img/first-cube-img.png");
  background-repeat: no-repeat;
  background-position: center right;
}
.trade-eagle__wrap__title {
  font-size: 50px;
  color: #0AEBFA;
  margin-bottom: 0;
}
.btn-default__third {
  font-family: EncodeSansLight, sans-serif;
  font-weight: 400;
  font-size: 20px;
  color: #fff;
}
.trade-eagle__text {
  font-family: EncodeSansLight, sans-serif;
  font-weight: 400;
  font-size: 20px;
  color: #fff;
  max-width: 282px;
  margin: 30px auto 0;
  text-align: center;
}
.eagle-trade__list {
  padding-top: 70px;
}
.eagle-trade__item {
  display: flex;
  justify-content: space-between;
  border-bottom: 1px solid rgba(255, 255, 255, 0.15);
  padding-bottom: 13px;
  padding-top: 22px;
}
.eagle-trade__name {
  font-size: 16px;
  color: #fff;
}
.eagle-trade__title {
  font-size: 16px;
  color: #6A6E87;
}
</style>
