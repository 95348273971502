<template>
  <div class="container-padding first-banner">
    <video class="background-video" playsinline autoplay loop muted poster="@/assets/img/banner-img.png">
      <source src="@/assets/videos/banner.mp4" type="video/mp4">
    </video>
    <Menu />
    <h1 class="banner__title text-gradient">{{ $t('tools.banner') }}</h1>
    <p class="banner__text" v-html="$t('tools.banner_text')"></p>
    <div class="d-flex justify-content-center">
      <a :href="'https://mycabinet.ipo-markets.net/signup?lang=' + $i18n.locale" class="btn-default">{{ $t('start_trading') }}</a>
    </div>
  </div>
</template>

<script>
/* eslint-disable */
import Menu from "@/components/Menu";

export default {
  name: 'Banner',
  components: {
    Menu
  },
}

</script>

<style lang="scss" scoped>
.first-banner {
  min-height: 100vh;
  position: relative;
  padding-bottom: 258px;

  &:after {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    background-color: rgba(0, 0, 0, .5);
    width: 100%;
    height: 100%;
    z-index: -1;
  }
}

.background-video {
  display: block;
  margin: 0 auto;
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: center;
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  z-index: -1;
}

.banner__title {
  font-family: EncodeSansBold, sans-serif;
  font-size: 90px;
  line-height: 1.3;
}

.banner__text {
  font-size: 30px;
  max-width: 930px;
  text-align: center;
  margin: 0 auto 50px;
  color: #fff;
}

.banner-dignity__item {
  font-size: 25px;
  max-width: 300px;
  width: 100%;
  text-align: center;
  color: #fff;
  position: relative;
  padding-bottom: 15px;

  &:after {
    content: '';
    position: absolute;
    background-color: #0AEBFA;
    border-radius: 10px;
    width: 100%;
    height: 5px;
    bottom: 0;
    left: 0;
  }
}
</style>
