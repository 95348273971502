<template>
  <div class="container-padding online-forex__wrap">
    <div class="col-12 col-md-6">
      <h4 class="online-forex__title">{{ $t('online_forex_title') }}</h4>
      <div class="online-forex__container" v-html="$t('online_forex_text')"></div>
      <a :href="'https://mycabinet.ipo-markets.net/signup?lang=' + $i18n.locale" class="btn-default__second">{{ $t('open_account') }}</a>
    </div>
    <div class="d-flex d-none d-md-block" id="cube-animation">
      <img src="@/assets/img/second-cube-img.png" alt="" class="img-fluid cube-animation">
    </div>
  </div>
</template>

<script>
/* eslint-disable */
import {gsap} from 'gsap';
import ScrollTrigger from "gsap/ScrollTrigger";
gsap.registerPlugin(ScrollTrigger);
export default {
  name: "OnlineForex",
  mounted() {
    gsap.timeline().to(".cube-animation", {
      x: 130,
      scrollTrigger: {
        trigger: ".cube-animation",
        start: "top center",
        end: "top 200px",
        scrub: 2,
        id: "scrub"
      }
    })
    .to(".cube-animation", {
      y: 1100,
      scale: .6,
      rotationZ: 45,
      scrollTrigger: {
        trigger: ".cube-animation",
        start: "top 100px",
        end: "bottom 0px",
        scrub: 3,
        id: "scrub"
      }
    });
  }
}
</script>

<style lang="scss" scoped>
.online-forex__wrap {
  position: relative;
  padding-top: 110px;
}
.cube-animation {
  position: absolute;
  top: 50px;
  right: 15%;
  max-width: 600px;
}
.online-forex__title {
  font-size: 50px;
  color: #0AEBFA;
  margin-bottom: 50px;
}
.online-forex__container {
  font-size: 18px;
  background-color: rgba(21, 21, 29, 0.6);
  padding: 25px;
  border-left: 1px solid #FF83B7;
  color: #fff;
}
</style>
