<template>
  <div class="container-padding trade-benefit__wrap">
    <div class="map__container d-block d-md-none">
      <img src="@/assets/img/map.png" alt="" class="img-fluid map__img">
    </div>
    <h3 class="trade-benefit__title">{{ $t('trade_benefit_title') }}</h3>
    <div class="trade-benefit__container">
      <p class="mb-0">
        {{ $t('trade_benefit_text_01') }}
      </p>
      <p class="mb-0">
        {{ $t('trade_benefit_text_02') }}
      </p>
    </div>
    <div class="d-flex justify-content-start justify-content-md-center">
      <a :href="'https://mycabinet.ipo-markets.net/signup?lang=' + $i18n.locale" class="btn-default">{{ $t('start_trading') }}</a>
    </div>
  </div>
</template>

<script>
export default {
  name: "TradeBenefits"
}
</script>

<style lang="scss" scoped>

.map__img {
  object-fit: contain;
  width:100%; height:100%;
}
.trade-benefit__wrap {
  padding-top: 110px;
}
.trade-benefit__title {
  font-size: 50px;
  color: #0AEBFA;
  text-align: center;
  margin-bottom: 30px;
}
.trade-benefit__container {
  max-width: 1036px;
  margin: auto;
  display: flex;
  flex-direction: column;
  gap: 20px;
  p {
    font-size: 28px;
    color: #fff;
    text-align: center;
  }
}
</style>
